<template>
  <div class="mb-6">
    <v-autocomplete
        chips
        clearable
        dense
        hide-details
        :label="label"
        :multiple="true"
        @change="change"
        :items="items"
        item-text="desc"
        item-value="desc"
        append-icon=""
        deletable-chips
        small-chips
        v-model="model"
    >

    </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: "CommonTagIndex",
  data() {
    return {
      items: [],
      model: ''
    }
  },
  props: {
    label: {
      type: String,
      default: '选择标签'
    },
    keyname: {
      type: String,
      default: 'hoteltags'
    },
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
    change() {
      this.$emit('change', Array.isArray(this.model) ? this.model.join(',') : this.model)
    }
  },
  created() {
    this.axios.post(this.select_data, {keyname: this.keyname}).then((res) => {
      this.items = res.data[0].values
    })
  },
  watch: {
    value: {
      handler(v) {
        this.model = v.split(',')
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-chip {
    margin: 4px !important;
    background: var(--v-primary-base) !important;
    color: #ffffff;
  }

  .mdi-close-circle::before {
    color: #ffffff;
  }
}
</style>
